import { DEEP_CLONE } from '@/utils/constants'
/**机构治理报告 */
const RISK_OPTIONS = [
  {
    value: '3',
    label: '风险项',
    parentValue: '1',
    showChildren: false
  },
  {
    value: '4',
    label: '优化项',
    parentValue: '1',
    showChildren: false
  }
]
// 关于状态的集合
const STATUS_OPTIONS = [
  {
    value: '1',
    label: '疑问'
  },
  {
    value: '2',
    label: '成功'
  },
  {
    value: '3',
    label: '失败'
  }
]
const PROBLEM_OPTIONS = [
  // {
  //   value: '2-1',
  //   label: '药品通用名错误',
  //   showChildren: false
  // },
  // {
  //   value: '2-2',
  //   label: '规格与阳采平台不一致',
  //   showChildren: false
  // },
  // {
  //   value: '2-3',
  //   label: '厂商与阳采平台不一致',
  //   showChildren: false
  // },
  // {
  //   value: '2-4',
  //   label: '剂型有错误',
  //   showChildren: false
  // },
  // {
  //   value: '2-5',
  //   label: '剂型有问题',
  //   showChildren: false
  // }
  {
    value: '1',
    label: '缺失',
    parentValue: '2',
    showChildren: false
  },
  {
    value: '2',
    label: '不准确',
    parentValue: '2',
    showChildren: false
  },
  {
    value: '3',
    label: '不完整',
    parentValue: '2',
    showChildren: false
  },
  {
    value: '4',
    label: '颗粒度大',
    parentValue: '2',
    showChildren: false
  },
  {
    value: '5',
    label: '不规范',
    parentValue: '2',
    showChildren: false
  }
]
const FILTER_OPTIONS = [
  {
    value: '1',
    label: '风险级别',
    showChildren: false,
    children: DEEP_CLONE(RISK_OPTIONS)
  },
  {
    value: '2',
    label: '问题类型',
    showChildren: false,
    children: DEEP_CLONE(PROBLEM_OPTIONS)
  }
]
const GRADE_OPTIONS = [
  {
    // 失败
    value: '3',
    label: '',
    showChildren: false,
    icon: 'kc-empty',
    children: [
      {
        label: '问题类型',
        value: '3-1',
        options: [
          {
            value: '2-1',
            label: '药品通用名错误',
            showChildren: false
          },
          {
            value: '2-2',
            label: '规格与阳采平台不一致',
            showChildren: false
          },
          {
            value: '2-3',
            label: '厂商与阳采平台不一致',
            showChildren: false
          },
          {
            value: '2-4',
            label: '剂型有错误',
            showChildren: false
          },
          {
            value: '2-5',
            label: '剂型有问题',
            showChildren: false
          }
        ]
      },
      {
        label: '风险级别',
        value: '3-2',
        options: [
          {
            value: '1-1',
            label: '风险项',
            showChildren: false
          },
          {
            value: '1-2',
            label: '优化项',
            showChildren: false
          }
        ]
      }
    ]
  },
  {
    // 疑问
    value: '1',
    label: '',
    showChildren: false,
    icon: 'wenhao'
  },
  {
    // 成功
    value: '2',
    label: '',
    showChildren: false,
    icon: 'duihao2'
  }
]
const TABLE_COLUMN_LIST = [

  'nhsaCodeCompare',
  'classCompare',
  'genericNameCompare',
  'tradeNameCompare',
  'specTextCompare',
  'specCompare',
  'formNameCompare',
  'manufacturerCompare',
  'approvalNoCompare',
  'nedFlagCompare',

  'highRiskLvCompare',
  'antibioticLvCompare',
  'toxicNarcoticTypeCompare',
  'dddCompare',
  'phaClassCompare',
  'ncpFlagCompare'
]
// const TABLE_COLUMN_LIST = [

// ]
const TABLE_ITEM = {
  ustdCode: '', // 编码
  genericNameCompare: '', // 通用名
  specCompare: '',
  formNameCompare: '',
  manufacturerCompare: '',
  approvalNoCompare: ''
}
const TABLE_DATA_ORIGIN = [
  {
    "ustdCode": "测试药品编码_101",
    "genericNameCompare": {
      "orgRelatedName": "厂家通用名",
      "platformRelatedName": "平台通用名",
      "status": "1",
      "statusStr": "临时放下",
      riskLevel: '3', // 风险级别 3为风险项 4为优化项
      riskLevelStr: '风险项',
      errorType: '1',
      errorTypeStr: '缺失'
    },
    "specCompare": {
      "orgRelatedName": "厂家规格名",
      "platformRelatedName": "平台规格名",
      "status": "1",
      "statusStr": "临时放下",
      riskLevel: '4', // 风险级别 3为风险项 4为优化项
      riskLevelStr: '优化项',
      errorType: '1',
      errorTypeStr: '缺失'
    },
    "formNameCompare": {
      "orgRelatedName": "厂家剂型名",
      "platformRelatedName": "平台剂型名",
      "status": "1",
      "statusStr": "临时放下",
      riskLevel: '3', // 风险级别 3为风险项 4为优化项
      riskLevelStr: '风险项',
      errorType: '1',
      errorTypeStr: '缺失'
    },
    "manufacturerCompare": {
      "orgRelatedName": "厂家厂商名",
      "platformRelatedName": "平台厂商名",
      "status": "1",
      "statusStr": "临时放下",
      riskLevel: '3', // 风险级别 3为风险项 4为优化项
      riskLevelStr: '风险项',
      errorType: '1',
      errorTypeStr: '缺失'
    },
    "approvalNoCompare": {
      "orgRelatedName": "厂家批准文号名",
      "platformRelatedName": "平台批准文号名",
      "status": "1",
      "statusStr": "临时放下",
      riskLevel: '4', // 风险级别 3为风险项 4为优化项
      riskLevelStr: '风险项',
      errorType: '1',
      errorTypeStr: '缺失'
    }
  },
  {
    "ustdCode": "测试药品编码_101",
    "genericNameCompare": {
      "orgRelatedName": "厂家通用名",
      "platformRelatedName": "平台通用名",
      "status": "1",
      "statusStr": "临时放下",
      riskLevel: '2', // 风险级别 3为风险项 4为优化项
      riskLevelStr: '合格',
      errorType: '1',
      errorTypeStr: '缺失'
    },
    "specCompare": {
      "orgRelatedName": "厂家规格名",
      "platformRelatedName": "平台规格名",
      "status": "1",
      "statusStr": "临时放下",
      riskLevel: '4', // 风险级别 3为风险项 4为优化项
      riskLevelStr: '优化项',
      errorType: '1',
      errorTypeStr: '缺失'
    },
    "formNameCompare": {
      "orgRelatedName": "厂家剂型名",
      "platformRelatedName": "平台剂型名",
      "status": "1",
      "statusStr": "临时放下",
      riskLevel: '4', // 风险级别 3为风险项 4为优化项
      riskLevelStr: '风险项',
      errorType: '1',
      errorTypeStr: '缺失'
    },
    "manufacturerCompare": {
      "orgRelatedName": "厂家厂商名",
      "platformRelatedName": "平台厂商名",
      "status": "1",
      "statusStr": "临时放下",
      riskLevel: '3', // 风险级别 3为风险项 4为优化项
      riskLevelStr: '风险项',
      errorType: '1',
      errorTypeStr: '缺失'
    },
    "approvalNoCompare": {
      "orgRelatedName": "厂家批准文号名",
      "platformRelatedName": "平台批准文号名",
      "status": "1",
      "statusStr": "临时放下",
      riskLevel: '4', // 风险级别 3为风险项 4为优化项
      riskLevelStr: '风险项',
      errorType: '1',
      errorTypeStr: '缺失'
    }
  },
  {
    "ustdCode": "测试药品编码_101",
    "genericNameCompare": {
      "orgRelatedName": "厂家通用名",
      "platformRelatedName": "平台通用名",
      "status": "1",
      "statusStr": "临时放下",
      riskLevel: '3', // 风险级别 3为风险项 4为优化项
      riskLevelStr: '风险项',
      errorType: '1',
      errorTypeStr: '缺失'
    },
    "specCompare": {
      "orgRelatedName": "厂家规格名",
      "platformRelatedName": "平台规格名",
      "status": "1",
      "statusStr": "临时放下",
      riskLevel: '4', // 风险级别 3为风险项 4为优化项
      riskLevelStr: '优化项',
      errorType: '1',
      errorTypeStr: '缺失'
    },
    "formNameCompare": {
      "orgRelatedName": "厂家剂型名",
      "platformRelatedName": "平台剂型名",
      "status": "1",
      "statusStr": "临时放下",
      riskLevel: '3', // 风险级别 3为风险项 4为优化项
      riskLevelStr: '风险项',
      errorType: '1',
      errorTypeStr: '缺失'
    },
    "manufacturerCompare": {
      "orgRelatedName": "厂家厂商名",
      "platformRelatedName": "平台厂商名",
      "status": "1",
      "statusStr": "临时放下",
      riskLevel: '3', // 风险级别 3为风险项 4为优化项
      riskLevelStr: '风险项',
      errorType: '1',
      errorTypeStr: '缺失'
    },
    "approvalNoCompare": {
      "orgRelatedName": "厂家批准文号名",
      "platformRelatedName": "平台批准文号名",
      "status": "1",
      "statusStr": "临时放下",
      riskLevel: '4', // 风险级别 3为风险项 4为优化项
      riskLevelStr: '风险项',
      errorType: '1',
      errorTypeStr: '缺失'
    }
  },
  {
    "ustdCode": "测试药品编码_101",
    "genericNameCompare": {
      "orgRelatedName": "厂家通用名",
      "platformRelatedName": "平台通用名",
      "status": "1",
      "statusStr": "临时放下",
      riskLevel: '3', // 风险级别 3为风险项 4为优化项
      riskLevelStr: '风险项',
      errorType: '1',
      errorTypeStr: '缺失'
    },
    "specCompare": {
      "orgRelatedName": "厂家规格名",
      "platformRelatedName": "平台规格名",
      "status": "1",
      "statusStr": "临时放下",
      riskLevel: '4', // 风险级别 3为风险项 4为优化项
      riskLevelStr: '优化项',
      errorType: '1',
      errorTypeStr: '缺失'
    },
    "formNameCompare": {
      "orgRelatedName": "厂家剂型名",
      "platformRelatedName": "平台剂型名",
      "status": "1",
      "statusStr": "临时放下",
      riskLevel: '3', // 风险级别 3为风险项 4为优化项
      riskLevelStr: '风险项',
      errorType: '1',
      errorTypeStr: '缺失'
    },
    "manufacturerCompare": {
      "orgRelatedName": "厂家厂商名",
      "platformRelatedName": "平台厂商名",
      "status": "1",
      "statusStr": "临时放下",
      riskLevel: '3', // 风险级别 3为风险项 4为优化项
      riskLevelStr: '风险项',
      errorType: '1',
      errorTypeStr: '缺失'
    },
    "approvalNoCompare": {
      "orgRelatedName": "厂家批准文号名",
      "platformRelatedName": "平台批准文号名",
      "status": "1",
      "statusStr": "临时放下",
      riskLevel: '4', // 风险级别 3为风险项 4为优化项
      riskLevelStr: '风险项',
      errorType: '1',
      errorTypeStr: '缺失'
    }
  },
  {
    "ustdCode": "测试药品编码_101",
    "genericNameCompare": {
      "orgRelatedName": "厂家通用名",
      "platformRelatedName": "平台通用名",
      "status": "1",
      "statusStr": "临时放下",
      riskLevel: '3', // 风险级别 3为风险项 4为优化项
      riskLevelStr: '风险项',
      errorType: '1',
      errorTypeStr: '缺失'
    },
    "specCompare": {
      "orgRelatedName": "厂家规格名",
      "platformRelatedName": "平台规格名",
      "status": "1",
      "statusStr": "临时放下",
      riskLevel: '4', // 风险级别 3为风险项 4为优化项
      riskLevelStr: '优化项',
      errorType: '1',
      errorTypeStr: '缺失'
    },
    "formNameCompare": {
      "orgRelatedName": "厂家剂型名",
      "platformRelatedName": "平台剂型名",
      "status": "1",
      "statusStr": "临时放下",
      riskLevel: '3', // 风险级别 3为风险项 4为优化项
      riskLevelStr: '风险项',
      errorType: '1',
      errorTypeStr: '缺失'
    },
    "manufacturerCompare": {
      "orgRelatedName": "厂家厂商名",
      "platformRelatedName": "平台厂商名",
      "status": "1",
      "statusStr": "临时放下",
      riskLevel: '3', // 风险级别 3为风险项 4为优化项
      riskLevelStr: '风险项',
      errorType: '1',
      errorTypeStr: '缺失'
    },
    "approvalNoCompare": {
      "orgRelatedName": "厂家批准文号名",
      "platformRelatedName": "平台批准文号名",
      "status": "1",
      "statusStr": "临时放下",
      riskLevel: '4', // 风险级别 3为风险项 4为优化项
      riskLevelStr: '风险项',
      errorType: '1',
      errorTypeStr: '缺失'
    }
  },
  {
    "ustdCode": "测试药品编码_101",
    "genericNameCompare": {
      "orgRelatedName": "厂家通用名",
      "platformRelatedName": "平台通用名",
      "status": "1",
      "statusStr": "临时放下",
      riskLevel: '3', // 风险级别 3为风险项 4为优化项
      riskLevelStr: '风险项',
      errorType: '1',
      errorTypeStr: '缺失'
    },
    "specCompare": {
      "orgRelatedName": "厂家规格名",
      "platformRelatedName": "平台规格名",
      "status": "1",
      "statusStr": "临时放下",
      riskLevel: '4', // 风险级别 3为风险项 4为优化项
      riskLevelStr: '优化项',
      errorType: '1',
      errorTypeStr: '缺失'
    },
    "formNameCompare": {
      "orgRelatedName": "厂家剂型名",
      "platformRelatedName": "平台剂型名",
      "status": "1",
      "statusStr": "临时放下",
      riskLevel: '3', // 风险级别 3为风险项 4为优化项
      riskLevelStr: '风险项',
      errorType: '1',
      errorTypeStr: '缺失'
    },
    "manufacturerCompare": {
      "orgRelatedName": "厂家厂商名",
      "platformRelatedName": "平台厂商名",
      "status": "1",
      "statusStr": "临时放下",
      riskLevel: '3', // 风险级别 3为风险项 4为优化项
      riskLevelStr: '风险项',
      errorType: '1',
      errorTypeStr: '缺失'
    },
    "approvalNoCompare": {
      "orgRelatedName": "厂家批准文号名",
      "platformRelatedName": "平台批准文号名",
      "status": "1",
      "statusStr": "临时放下",
      riskLevel: '4', // 风险级别 3为风险项 4为优化项
      riskLevelStr: '风险项',
      errorType: '1',
      errorTypeStr: '缺失'
    }
  },
  {
    "ustdCode": "测试药品编码_101",
    "genericNameCompare": {
      "orgRelatedName": "厂家通用名",
      "platformRelatedName": "平台通用名",
      "status": "1",
      "statusStr": "临时放下",
      riskLevel: '3', // 风险级别 3为风险项 4为优化项
      riskLevelStr: '风险项',
      errorType: '1',
      errorTypeStr: '缺失'
    },
    "specCompare": {
      "orgRelatedName": "厂家规格名",
      "platformRelatedName": "平台规格名",
      "status": "1",
      "statusStr": "临时放下",
      riskLevel: '4', // 风险级别 3为风险项 4为优化项
      riskLevelStr: '优化项',
      errorType: '1',
      errorTypeStr: '缺失'
    },
    "formNameCompare": {
      "orgRelatedName": "厂家剂型名",
      "platformRelatedName": "平台剂型名",
      "status": "1",
      "statusStr": "临时放下",
      riskLevel: '3', // 风险级别 3为风险项 4为优化项
      riskLevelStr: '风险项',
      errorType: '1',
      errorTypeStr: '缺失'
    },
    "manufacturerCompare": {
      "orgRelatedName": "厂家厂商名",
      "platformRelatedName": "平台厂商名",
      "status": "1",
      "statusStr": "临时放下",
      riskLevel: '3', // 风险级别 3为风险项 4为优化项
      riskLevelStr: '风险项',
      errorType: '1',
      errorTypeStr: '缺失'
    },
    "approvalNoCompare": {
      "orgRelatedName": "厂家批准文号名",
      "platformRelatedName": "平台批准文号名",
      "status": "1",
      "statusStr": "临时放下",
      riskLevel: '4', // 风险级别 3为风险项 4为优化项
      riskLevelStr: '风险项',
      errorType: '2',
      errorTypeStr: '不准确'
    }
  }
]
const TABLE_DATA = []
const HISTORY_LIST = [
  {
    id: 1,
    status: '2',
    name: 'admin',
    pushTime: '',
    createTime: '2021-07-08 12:00:03',
    healthIndexScore: 34
  },
  {
    id: 1,
    status: '2',
    name: 'admin',
    pushTime: '',
    createTime: '2021-07-08 12:00:03',
    healthIndexScore: 34
  },
  {
    id: 2,
    status: '1',
    name: 'admin',
    pushTime: '2021-07-08 12:00:03',
    createTime: '2021-07-08 12:00:03',
    healthIndexScore: 34
  },
  {
    id: 3,
    status: '1',
    name: 'admin',
    pushTime: '2021-07-08 12:00:03',
    createTime: '2021-07-08 12:00:03',
    healthIndexScore: 34
  },
  {
    id: 4,
    status: '1',
    name: 'admin',
    pushTime: '2021-07-08 12:00:03',
    createTime: '2021-07-08 12:00:03',
    healthIndexScore: 34
  },
  {
    id: 5,
    status: '1',
    name: 'admin',
    pushTime: '2021-07-08 12:00:03',
    createTime: '2021-07-08 12:00:03',
    healthIndexScore: 34
  },
  {
    id: 6,
    status: '1',
    name: 'admin',
    pushTime: '2021-07-08 12:00:03',
    createTime: '2021-07-08 12:00:03',
    healthIndexScore: 34
  },
  {
    id: 7,
    status: '1',
    name: 'admin',
    pushTime: '2021-07-08 12:00:03',
    createTime: '2021-07-08 12:00:03',
    healthIndexScore: 34
  },
  {
    id: 8,
    status: '1',
    name: 'admin',
    pushTime: '2021-07-08 12:00:03',
    createTime: '2021-07-08 12:00:03',
    healthIndexScore: 34
  }
]
// 未匹配或已匹配分页查询的参数
const NO_MATCH_TABLE_SEARCH_OBJ = {
  isFilter: false, // 是否是筛选模式
  applyId: '',
  errorProblemnFlag: false, // 是否打开问题字段
  errorFilterFlag: '', // 只看问题字段-pc端使用 1为加入该过滤,其他为不过滤
  errorCodeList: [], // 错误原因-使用code
  pageNum: 1, // 当前记录起始索引
  pageSize: 10, // 每页显示记录数
  searchValue: '', // 编号/药品通用名
  filterList: [], // 过滤集合
  filterErrorTypeList: [], // 筛选模式---问题类型集合
  filterFieldName: [], // 筛选模式---需要增加过滤条件的字段名称
  filterRiskLevelList: [] // 筛选模式---风险层级集合
}
// 左侧健康指数、雷达图
const SCORE_DATA_OBJ = {
  updateTime: '', // 数据更新时间
  recordId: '',
  completeRatio: '', // 完成率
  matchRatio: '', // 匹配度
  healthIndexScore: '', // 健康指数
  indexCompareAbsoluteValue: '', // 指数比较绝对值 如无上次数据,相关内容用不传,需要前端使用 --进行填充
  indexCompareTrend: '', // 指数比较趋势 -1:下降 0:持平 1:上升 空值:无上次数据
  missingDataItemScore: '', // 数据缺失项
  optimizationItemScore: '', // 优化项
  riskItemScore: '', // 风险项
  dataMatchItemScore: '' // 未匹配项-数据匹配
}
// 历史版本查询条件
const HISTORY_QUERY_OBJ = {
  applyId: '',
  timeArr: [], // 日期范围数组
  startTime: '', // 开始时间 yyyy-MM-dd
  endTime: '', // 结束时间 yyyy-MM-dd
  pageNum: 1, // 当前记录起始索引
  pageSize: 9999, // 每页显示记录数
  searchValue: '', // 机构名称,在平台端使用
  status: '' // 状态 1:报告生成中 2:已发布
}
export {
  FILTER_OPTIONS,
  GRADE_OPTIONS,
  RISK_OPTIONS,
  PROBLEM_OPTIONS,
  TABLE_DATA,
  TABLE_DATA_ORIGIN,
  HISTORY_LIST,
  NO_MATCH_TABLE_SEARCH_OBJ,
  HISTORY_QUERY_OBJ,
  SCORE_DATA_OBJ,
  TABLE_COLUMN_LIST,
  STATUS_OPTIONS
}
