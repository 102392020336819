/**平台审核报告页面 */


export const CHECK_REPORT_PROPERTY_COLUMN = [
  {
    id: 2,
    prop: 'genericNameCompare',
    name: '药品通用名',
    fixed: false,
    show: true,
    sortable:'custom',
    align: 'center',
    width: '',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 4,
    prop: 'specCompare',
    name: '规格',
    fixed: false,
    show: true,
    sortable:'custom',
    align: 'center',
    width: '100',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 6,
    prop: 'formNameCompare',
    name: '剂型名称',
    fixed: false,
    show: true,
    sortable:'custom',
    align: 'center',
    width: '150',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 8,
    prop: 'manufacturerCompare',
    name: '厂商',
    fixed: false,
    show: true,
    sortable:'custom',
    align: 'center',
    width: '',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 10,
    prop: 'approvalNoCompare',
    name: '批准文号',
    fixed: false,
    show: true,
    sortable:'custom',
    align: 'center',
    width: '',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 12,
    rank: 2,
    label: '抗菌药分级名称',
    name: '抗菌药分级名称',
    prop: 'antibioticLvCompare',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '',
    minWidth: '200',
    align: 'center',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 14,
    rank: 14,
    label: '中西草生物制品类型名称',
    name: '中西草生物制品类型名称',
    prop: 'classCompare',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '',
    minWidth: '200',
    align: 'center',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 16,
    rank: 16,
    label: 'DDD',
    name: 'DDD',
    prop: 'dddCompare',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '',
    minWidth: '200',
    align: 'center',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 18,
    rank: 18,
    label: '高危药品分级名称',
    name: '高危药品分级名称',
    prop: 'highRiskLvCompare',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '',
    minWidth: '200',
    align: 'center',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 20,
    rank: 20,
    label: '国基标志',
    name: '国基标志',
    prop: 'nedFlagCompare',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '',
    minWidth: '',
    align: 'center',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 22,
    rank: 22,
    label: '上基标志',
    name: '上基标志',
    prop: 'shNedFlagCompare',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '',
    minWidth: '',
    align: 'center',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 24,
    rank: 24,
    label: '药品分类',
    name: '药品分类',
    prop: 'phaClassCompare',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '',
    minWidth: '200',
    align: 'center',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 26,
    rank: 26,
    label: '药品规格说明',
    name: '药品规格说明',
    prop: 'specTextCompare',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '',
    minWidth: '200',
    align: 'center',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 28,
    rank: 28,
    label: '精麻毒放',
    name: '精麻毒放',
    prop: 'toxicNarcoticTypeCompare',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '',
    minWidth: '200',
    align: 'center',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 30,
    rank: 30,
    label: '药品商品名',
    name: '药品商品名',
    prop: 'tradeNameCompare',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '',
    minWidth: '200',
    align: 'center',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 32,
    rank: 32,
    label: '上海阳光采购平台药品统编码',
    name: '上海阳光采购平台药品统编码',
    prop: 'ustdCodeCompare',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '',
    minWidth: '200',
    align: 'center',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  }
]
export const CHECK_REPORT_STATUS_COLUMN = [
  {
    id: 3,
    prop: 'genericNameCompareStatus',
    name: '',
    fixed: false,
    show: false,
    sortable:'custom',
    align: 'center',
    width: '50',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 5,
    prop: 'specCompareStatus',
    name: '',
    fixed: false,
    show: false,
    sortable:'custom',
    align: 'center',
    width: '50',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 7,
    prop: 'formNameCompareStatus',
    name: '',
    fixed: false,
    show: false,
    sortable:'custom',
    align: 'center',
    width: '50',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 9,
    prop: 'manufacturerCompareStatus',
    name: '',
    fixed: false,
    show: false,
    sortable:'custom',
    align: 'center',
    width: '50',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 11,
    prop: 'approvalNoCompareStatus',
    name: '',
    fixed: false,
    show: false,
    sortable:'custom',
    align: 'center',
    width: '50',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 13,
    prop: 'antibioticLvCompareStatus',
    name: '',
    fixed: false,
    show: false,
    sortable:'custom',
    align: 'center',
    width: '50',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 15,
    prop: 'classCompareStatus',
    name: '',
    fixed: false,
    show: false,
    sortable:'custom',
    align: 'center',
    width: '50',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 17,
    prop: 'dddCompareStatus',
    name: '',
    fixed: false,
    show: false,
    sortable:'custom',
    align: 'center',
    width: '50',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 19,
    prop: 'highRiskLvCompareStatus',
    name: '',
    fixed: false,
    show: false,
    sortable:'custom',
    align: 'center',
    width: '50',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 21,
    prop: 'nedFlagCompareStatus',
    name: '',
    fixed: false,
    show: false,
    sortable:'custom',
    align: 'center',
    width: '50',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 23,
    prop: 'shNedFlagCompareStatus',
    name: '',
    fixed: false,
    show: false,
    sortable:'custom',
    align: 'center',
    width: '50',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 25,
    prop: 'phaClassCompareStatus',
    name: '',
    fixed: false,
    show: false,
    sortable:'custom',
    align: 'center',
    width: '50',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 27,
    prop: 'specTextCompareStatus',
    name: '',
    fixed: false,
    show: false,
    sortable:'custom',
    align: 'center',
    width: '50',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 29,
    prop: 'toxicNarcoticTypeCompareStatus',
    name: '',
    fixed: false,
    show: false,
    sortable:'custom',
    align: 'center',
    width: '50',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 31,
    prop: 'tradeNameCompareStatus',
    name: '',
    fixed: false,
    show: false,
    sortable:'custom',
    align: 'center',
    width: '50',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 33,
    prop: 'ustdCodeCompareStatus',
    name: '',
    fixed: false,
    show: false,
    sortable:'custom',
    align: 'center',
    width: '50',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  }
]
export const CHECK_REPORT_COLUMN = [
  /**
   * errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
   errorTypeStr: '',
   riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
   riskLevelStr: '',
   status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
   statusStr: ''
   */
  {
    id: 1,
    prop: 'drugId',
    name: '药品编码',
    show: true,
    fixed: true,
    sortable:'custom',
    align: 'center',
    width: '170',
    minWidth: '120',
    isTypeTag: false, // 用来说明该行数据是机构还是平台
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 2,
    prop: '',
    name: '',
    fixed: true,
    show: false,
    sortable:'custom',
    align: 'center',
    width: '50',
    minWidth: '',
    isTypeTag: true, // 用来说明该行数据是机构还是平台
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  // {
  //   id: 35,
  //   prop: 'nhsaCodeCompare',
  //   name: '国家贯标码',
  //   fixed: true,
  //   show: true,
  //   sortable:'custom',
  //   align: 'center',
  //   width: '170',
  //   minWidth: '120',
  //   isTypeTag: false, // 用来说明该行数据是机构还是平台
  //   errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
  //   errorTypeStr: '',
  //   riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
  //   riskLevelStr: '',
  //   status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
  //   statusStr: ''
  // },
  // {
  //   id: 36,
  //   prop: 'nhsaCodeCompareStatus',
  //   name: '',
  //   fixed: true,
  //   show: false,
  //   sortable:'custom',
  //   align: 'center',
  //   width: '50',
  //   isTypeTag: false, // 用来说明该行数据是机构还是平台
  //   errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
  //   errorTypeStr: '',
  //   riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
  //   riskLevelStr: '',
  //   status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
  //   statusStr: ''
  // },
  {
    id: 3,
    prop: 'genericNameCompare',
    name: '药品通用名',
    fixed: true,
    show: true,
    sortable:'custom',
    align: 'center',
    width: '170',
    minWidth: '120',
    isTypeTag: false, // 用来说明该行数据是机构还是平台
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 4,
    prop: 'genericNameCompareStatus',
    name: '',
    fixed: true,
    show: false,
    sortable:'custom',
    align: 'center',
    width: '50',
    isTypeTag: false, // 用来说明该行数据是机构还是平台
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 5,
    rank: 5,
    label: '药品商品名',
    name: '药品商品名',
    prop: 'tradeNameCompare',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '120',
    minWidth: '200',
    align: 'center',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 6,
    rank: 6,
    prop: 'tradeNameCompareStatus',
    name: '',
    fixed: false,
    show: false,
    sortable:'custom',
    align: 'center',
    width: '50',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 7,
    rank: 7,
    prop: 'specCompare',
    name: '规格',
    fixed: false,
    show: true,
    sortable:'custom',
    align: 'center',
    width: '160',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 8,
    rank: 8,
    prop: 'specCompareStatus',
    name: '',
    fixed: false,
    show: false,
    sortable:'custom',
    align: 'center',
    width: '50',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },

  {
    id: 9,
    rank: 9,
    label: '药品规格说明',
    name: '药品规格说明',
    prop: 'specTextCompare',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '110',
    minWidth: '200',
    align: 'center',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 10,
    rank: 10,
    prop: 'specTextCompareStatus',
    name: '',
    fixed: false,
    show: false,
    sortable:'custom',
    align: 'center',
    width: '50',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 11,
    rank: 11,
    prop: 'formNameCompare',
    name: '剂型名称',
    fixed: false,
    show: true,
    sortable:'custom',
    align: 'center',
    width: '130',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 12,
    rank: 12,
    prop: 'formNameCompareStatus',
    name: '',
    fixed: false,
    show: false,
    sortable:'custom',
    align: 'center',
    width: '50',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 13,
    rank: 13,
    prop: 'manufacturerCompare',
    name: '厂商',
    fixed: false,
    show: true,
    sortable:'custom',
    align: 'center',
    width: '160',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 14,
    rank: 14,
    prop: 'manufacturerCompareStatus',
    name: '',
    fixed: false,
    show: false,
    sortable:'custom',
    align: 'center',
    width: '50',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 15,
    rank: 15,
    prop: 'approvalNoCompare',
    name: '批准文号',
    fixed: false,
    show: true,
    sortable:'custom',
    align: 'center',
    width: '150',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 16,
    rank: 16,
    prop: 'approvalNoCompareStatus',
    name: '',
    fixed: false,
    show: false,
    sortable:'custom',
    align: 'center',
    width: '50',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 17,
    rank: 17,
    label: '高危药品分级名称',
    name: '高危药品分级名称',
    prop: 'highRiskLvCompare',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '120',
    minWidth: '200',
    align: 'center',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 18,
    rank: 18,
    prop: 'highRiskLvCompareStatus',
    name: '',
    fixed: false,
    show: false,
    sortable:'custom',
    align: 'center',
    width: '50',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },

  {
    id: 19,
    rank: 19,
    label: '抗菌药分级名称',
    name: '抗菌药分级名称',
    prop: 'antibioticLvCompare',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '120',
    minWidth: '200',
    align: 'center',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 20,
    rank: 20,
    prop: 'antibioticLvCompareStatus',
    name: '',
    fixed: false,
    show: false,
    sortable:'custom',
    align: 'center',
    width: '50',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 21,
    rank: 21,
    label: '国基标志',
    name: '国基标志',
    prop: 'nedFlagCompare',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '100',
    minWidth: '90',
    align: 'center',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 22,
    rank: 22,
    prop: 'nedFlagCompareStatus',
    name: '',
    fixed: false,
    show: false,
    sortable:'custom',
    align: 'center',
    width: '50',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },


  {
    id: 25,
    rank: 25,
    label: '药品分类',
    name: '药品分类',
    prop: 'phaClassCompare',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '',
    minWidth: '200',
    align: 'center',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 26,
    rank: 26,
    prop: 'phaClassCompareStatus',
    name: '',
    fixed: false,
    show: false,
    sortable:'custom',
    align: 'center',
    width: '50',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 27,
    rank: 27,
    label: '精麻毒放',
    name: '精麻毒放',
    prop: 'toxicNarcoticTypeCompare',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '120',
    minWidth: '200',
    align: 'center',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 28,
    rank: 28,
    prop: 'toxicNarcoticTypeCompareStatus',
    name: '',
    fixed: false,
    show: false,
    sortable:'custom',
    align: 'center',
    width: '50',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },

  {
    id: 29,
    rank: 29,
    label: 'DDD',
    name: 'DDD',
    prop: 'dddCompare',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '100',
    minWidth: '200',
    align: 'center',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 30,
    rank: 30,
    prop: 'dddCompareStatus',
    name: '',
    fixed: false,
    show: false,
    sortable:'custom',
    align: 'center',
    width: '50',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },

  {
    id: 31,
    rank: 31,
    label: '中西草生物制品类型名称',
    name: '中西草生物制品类型名称',
    prop: 'classCompare',
    sortable: false,
    isSplited: false, // 该列是否是由多个字段拼接
    show: true,
    fixed: false,
    width: '180',
    minWidth: '200',
    align: 'center',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 32,
    rank: 32,
    prop: 'classCompareStatus',
    name: '',
    fixed: false,
    show: false,
    sortable:'custom',
    align: 'center',
    width: '50',
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },


  {
    id: 37,
    prop: 'ncpFlagCompare',
    name: '国家集采药品标志',
    fixed: false,
    show: true,
    sortable:'custom',
    align: 'center',
    width: '170',
    minWidth: '120',
    isTypeTag: false, // 用来说明该行数据是机构还是平台
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 38,
    prop: 'ncpFlagCompareStatus',
    name: '',
    fixed: false,
    show: false,
    sortable:'custom',
    align: 'center',
    width: '50',
    isTypeTag: false, // 用来说明该行数据是机构还是平台
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 35,
    prop: 'nhsaCodeCompare',
    name: '国家贯标码',
    fixed: false,
    show: true,
    sortable:'custom',
    align: 'center',
    width: '170',
    minWidth: '120',
    isTypeTag: false, // 用来说明该行数据是机构还是平台
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
  {
    id: 36,
    prop: 'nhsaCodeCompareStatus',
    name: '',
    fixed: false,
    show: false,
    sortable:'custom',
    align: 'center',
    width: '50',
    isTypeTag: false, // 用来说明该行数据是机构还是平台
    errorType: '', // // 问题类型 1:缺失 2:不准确 3:不完整 4:颗粒度大 5:不规范,可用值:1,2,3,4,5
    errorTypeStr: '',
    riskLevel: '', // // 风险级别 3为风险项 4为优化项,可用值:3,4
    riskLevelStr: '',
    status: '', // 相关的结果 1:疑问 2:成功 3:失败,可用值:1,2,3
    statusStr: ''
  },
]
/* const tableData = [
  {
    drugId: 1,
    genericNameCompareStatus: '1',
    genericNameCompare: '2',
    drugIdFrom: '1'
  },
  {
    drugId: 1,
    genericNameCompareStatus: '1',
    genericNameCompare: '2',
    drugIdFrom: '2'
  },
  {
    drugId: 2,
    genericNameCompareStatus: '1',
    genericNameCompare: '2',
    drugIdFrom: '3'
  },
  {
    drugId: 2,
    genericNameCompareStatus: '1',
    genericNameCompare: '2',
    drugIdFrom: '4'
  },
  {
    drugId: 3,
    genericNameCompareStatus: '1',
    genericNameCompare: '2',
    drugIdFrom: '5'
  },
  {
    drugId: 3,
    genericNameCompareStatus: '1',
    genericNameCompare: '2',
    drugIdFrom: '6'
  },
  {
    drugId: 4,
    genericNameCompareStatus: '1',
    genericNameCompare: '2',
    drugIdFrom: '7'
  },
  {
    drugId: 4,
    genericNameCompareStatus: '1',
    genericNameCompare: '2',
    drugIdFrom: '8'
  },
  {
    drugId: 5,
    genericNameCompareStatus: '1',
    genericNameCompare: '2',
    drugIdFrom: '5i'
  },
  {
    drugId: 5,
    genericNameCompareStatus: '1',
    genericNameCompare: '2',
    drugIdFrom: '5p'
  },
  {
    drugId: 6,
    genericNameCompareStatus: '1',
    genericNameCompare: '2',
    drugIdFrom: '6i'
  },
  {
    drugId: 6,
    genericNameCompareStatus: '1',
    genericNameCompare: '2',
    drugIdFrom: '6p'
  },
  {
    drugId: 7,
    genericNameCompareStatus: '1',
    genericNameCompare: '2',
    drugIdFrom: '7i'
  },
  {
    drugId: 7,
    genericNameCompareStatus: '1',
    genericNameCompare: '2',
    drugIdFrom: '7p'
  },
  {
    drugId: 8,
    genericNameCompareStatus: '1',
    genericNameCompare: '2',
    drugIdFrom: '8i'
  },
  {
    drugId: 8,
    genericNameCompareStatus: '1',
    genericNameCompare: '2',
    drugIdFrom: '8p'
  },
  {
    drugId: 9,
    genericNameCompareStatus: '1',
    genericNameCompare: '2',
    drugIdFrom: '9i'
  },
  {
    drugId: 9,
    genericNameCompareStatus: '1',
    genericNameCompare: '2',
    drugIdFrom: '9p'
  },
  {
    drugId: 10,
    genericNameCompareStatus: '1',
    genericNameCompare: '2',
    drugIdFrom: '10i'
  },
  {
    drugId: 10,
    genericNameCompareStatus: '1',
    genericNameCompare: '2',
    drugIdFrom: '10p'
  }
] */
